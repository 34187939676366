<template>
  <div class="home">
    <v-row no-gutters>
      <v-col cols="7"><div class="mt-12 font-30 butler-700">Replacements-Step1</div></v-col>
      <v-col cols="5">
        <div class="mt-6 mb-3 ml-8 px-16">
          <p class="text-black mb-2">Select Status</p>
          <v-btn-toggle v-model="selectedStatus" dense color="black" class="select-color">
            <v-btn value="draft"> Draft </v-btn>
            <v-btn value="approve"> Approve </v-btn>
            <v-btn value="publish"> Publish </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            Replacement Name:
            <input
              type="name"
              class="form-control product-name mb-4"
              min-width="100px"
              id="name"
              placeholder="Enter Replacement Name"
              v-model="name"
            />
            <CategoryTree :data="categories" />
            <div class="padding-10"></div>
            <div class="">
              <div class="d-flex">
                <div class="font-26 butler-700 mr-4">Category</div>
                <button class="btn btn-success pull-right" @click="createCategory()">Create</button>
              </div>

              <div v-for="(category, index) in categories" v-bind:key="index">
                <div class="padding-10"></div>
                <div class="row category-section">
                  <div class="col-md-2 ml-8">
                    <div class="form-group">
                      <div class="delete-button-holder">
                        <div class="delete-button">
                          <button
                            type="button"
                            @click="deleteCategory(category)"
                            class="btn btn-outline-danger btn-sm"
                          >
                            Delete
                          </button>
                        </div>
                        <div class="show-button">
                          <button
                            type="button"
                            @click="toggleCategoryShow(category)"
                            class="btn btn-outline-info btn-sm"
                          >
                            <span v-show="!category.show">Show</span>
                            <span v-show="category.show">Hide</span>
                          </button>
                        </div>
                      </div>
                      <label class="font-14 ml-4" for="category-no">Sr. No.</label>
                      <p class="font-14 ml-4 black-txt">CAT {{ index + 1 }}</p>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="font-14 poppins-medium" for="category-name">Name</label>
                      <input
                        type="text"
                        class="category-name form-control"
                        id="category-name"
                        v-model="category.name"
                      />
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label class="font-14 poppins-medium" for="category-sequence">Sequence</label>
                      <input
                        type="text"
                        class="category-sequence form-control"
                        id="category-sequence"
                        v-model="category.sequence"
                      />
                    </div>
                  </div>
                </div>

                <div class="margin-left-1" v-if="category.show">
                  <div class="padding-10"></div>
                  <div class="row">
                    <div class="col-md-4 d-flex">
                      <div class="font-26 butler-700 mr-4">Layer</div>
                      <button class="btn btn-success pull-right" @click="createLayer(category)">
                        Create
                      </button>
                    </div>
                  </div>

                  <div v-for="(layer, index) in category.layers" v-bind:key="index">
                    <div class="padding-10"></div>
                    <div class="row layer-section">
                      <div class="col-md-2 px-0">
                        <div class="form-group pl-10">
                          <div class="delete-button-holder">
                            <div class="delete-button">
                              <button
                                type="button"
                                @click="deleteLayer(category, layer)"
                                class="btn btn-outline-danger btn-sm"
                              >
                                Delete
                              </button>
                            </div>
                            <div class="show-button">
                              <button
                                type="button"
                                @click="toggleLayerShow(category, layer)"
                                class="btn btn-outline-info btn-sm"
                              >
                                <span v-show="!layer.show">Show</span>
                                <span v-show="layer.show">Hide</span>
                              </button>
                            </div>
                          </div>
                          <label class="font-14 pl-3" for="layer-no">Sr. No.</label>
                          <p class="font-14 pl-3 black-txt">LAY {{ index + 1 }}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="font-14 poppins-medium" for="layer-name">Name</label>
                          <input
                            type="text"
                            class="layer-name form-control"
                            id="layer-name"
                            v-model="layer.name"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="font-14 poppins-medium" for="layer-icon"
                            >Icon (40 W&#10005;40 H)</label
                          >
                          <div>
                            <input
                              type="file"
                              min-width="10%"
                              class="form-control"
                              id="layer-icon"
                              @change="layerImagePickerClick($event, layer)"
                              :key="componentFrontKey"
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="font-14 poppins-medium" for="layer-icon">Type</label>
                          <select class="form-control" id="model" v-model="layer.typeOfLayer">
                            <option value="Image">Image</option>
                            <option value="Color">Color</option>
                            <option value="Embroidery Color">Embroidery Color</option>
                            <option value="Embroidery">Embroidery</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-1 px-0">
                        <div class="form-group">
                          <label class="font-14 poppins-medium" for="layer-sequence"
                            >Sequence</label
                          >
                          <input
                            type="text"
                            class="layer-sequence form-control"
                            id="layer-sequence"
                            v-model="layer.sequence"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="margin-left-1" v-if="layer.show">
                      <div class="padding-10"></div>
                      <div class="row">
                        <div class="col-md-12 d-flex">
                          <div class="font-26 butler-700 mr-4">Toggle</div>
                          <button class="btn btn-success pull-right" @click="createToggle(layer)">
                            Create
                          </button>
                        </div>
                      </div>

                      <div v-for="(toggle, index) in layer.toggles" v-bind:key="index">
                        <div class="padding-10"></div>
                        <div class="row toggle-section">
                          <div class="col-md-1 px-0 ml-8">
                            <div class="form-group">
                              <div class="delete-button-holder">
                                <div class="delete-button">
                                  <button
                                    type="button"
                                    @click="deleteToggle(layer, toggle)"
                                    class="btn btn-outline-danger btn-sm"
                                  >
                                    Delete
                                  </button>
                                </div>
                                <div class="show-button">
                                  <button
                                    type="button"
                                    @click="toggleToggleShow(layer, toggle)"
                                    class="btn btn-outline-info btn-sm"
                                  >
                                    <span v-show="!toggle.show">Show</span>
                                    <span v-show="toggle.show">Hide</span>
                                  </button>
                                </div>
                              </div>
                              <label class="font-14 poppins-medium ml-1" for="toggle-no"
                                >Sr.No.</label
                              >
                              <p class="font-14 poppins-medium ml-1 black-txt">
                                TOG {{ index + 1 }}
                              </p>
                            </div>
                          </div>
                          <div class="col-md-2 pl-0">
                            <div class="form-group">
                              <label class="font-14 poppins-medium" for="toggle-name">Name</label>
                              <input
                                type="text"
                                class="toggle-name form-control"
                                id="toggle-name"
                                v-model="toggle.name"
                                v-on:change="updateEmbToggleName(layer, toggle.name, $event)"
                                @focus="currentEditingToggleName = toggle.name"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="font-14 poppins-medium" for="toggle-name">price</label>
                              <input
                                type="number"
                                class="toggle-name form-control"
                                id="toggle-name"
                                v-model="toggle.price"
                              />
                            </div>
                          </div>
                          <div class="col-md-2 px-2">
                            <div class="form-group">
                              <label class="font-12 poppins-medium ml-3" for="toggle-icon"
                                >Icon(30 W&#10005;30 H)</label
                              >

                              <div>
                                <input
                                  type="file"
                                  class="toggle-icon form-control"
                                  id="toggle-icon"
                                  @change="toggleImagePickerClick($event, toggle)"
                                  :key="componentFrontKey"
                                  accept="image/*"
                                  name="file"
                                />
                                <!-- <input
                                      type="file"
                                      name="file"
                                      id="toggle-icon"
                                      @change="toggleImagePickerClick($event, toggle)"
                                      accept="image/*"
                                    /> -->
                              </div>
                              <div class="d-flex justify-center mt-1" v-if="toggle.icon">
                                <v-img :src="toggle.icon" max-width="30" max-height="30"></v-img>
                                <img
                                  src="@/assets/Images/remove.svg"
                                  alt="logo"
                                  class="ml-2"
                                  @click="toggle.icon = ''"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1 px-2 ml-2">
                            <div class="form-group">
                              <label class="font-14 poppins-medium" for="toggle-sequence"
                                >Sequence</label
                              >
                              <input
                                type="text"
                                class="toggle-sequence form-control"
                                id="toggle-sequence"
                                v-model="toggle.sequence"
                              />
                            </div>
                          </div>
                          <div class="col-md-3 px-1 py-3">
                            <div
                              class="form-group"
                              v-if="toggle.hex && layer.typeOfLayer == 'Color'"
                            >
                              <div class="d-flex ml-2 justify-center">
                                <div>
                                  <label class="font-14 poppins-medium" for="toggle-sequence"
                                    >Color
                                  </label>
                                </div>

                                <MultipleColorSelection
                                  :model="toggle.hex"
                                  mask="mask"
                                  single
                                  class="px-1"
                                  type="single"
                                ></MultipleColorSelection>
                              </div>
                            </div>
                            <div v-for="(item, index) in toggle.hex" :key="index">
                              <v-row class="d-flex ml-4 justify-center" no-gutters>
                                <v-col cols="9">
                                  <v-text-field
                                    v-model="item.color"
                                    class="mr-1"
                                    v-if="item.color"
                                    item-text="name"
                                    placeholder="hex code"
                                    dense
                                    small
                                    >{{ item.color }}
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3" class="py-1 d-flex">
                                  <div
                                    class="rounded-color pa-3"
                                    :style="'background-color: ' + item.color"
                                  ></div>
                                  <img
                                    src="@/assets/Images/remove.svg"
                                    alt="logo"
                                    class="ml-2"
                                    @click="
                                      ConfirmDeleteClr(item.color, category.id, layer.id, toggle.id)
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                        <div class="padding-10"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div class="form-group col">
                <div class="d-grid skucodes">
                  <label for="link" class="font-26 butler-700 mr-2"
                    >Apply SKU Codes
                    <v-btn class="ml-2" height="43" width="73" @click="addSku">Add</v-btn>
                  </label>
                </div>
                <div class="poppins-medium font-16 pl-4 d-block">
                  <v-row no-gutters>
                    <v-col cols="10" v-for="(item, index) in query" :key="index" class="px-6">
                      <div class="d-flex">
                        <v-text-field
                          v-if="item"
                          v-model="item.sku"
                          width="150px"
                          outlined
                          dense
                          class="mt-6 mr-2 px-2"
                          >{{ item.sku }}</v-text-field
                        >
                        <img
                          @click="deleteSKU(index)"
                          src="@/assets/Images/remove.svg"
                          alt="logo"
                          class="mt-2"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next-button-section">
          <!-- <div @click="saveBtnClick" class="btn btn-success btn-lg btn-block">Save</div> -->
          <div @click="nextBtnClick" class="btn btn-success btn-lg btn-block ml-10">
            Save & Next
          </div>
        </div>
      </div>

      <div>
        <v-dialog v-model="dialog" width="30%">
          <v-card>
            <div class="form-elements-sec">
              <div class="form-body">
                <form name="details" class="formPart text-left">
                  <div>
                    <div class="form-group mx-4 pt-4">
                      {{ 'The image size is too much (Requiring max is 75 X 75).' }}
                    </div>
                  </div>

                  <div class="btnPart text-center py-4">
                    <button type="reset" class="btn btn-danger mx-2" v-on:click="dialog = false">
                      Hide
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import _ from 'lodash';
import { v4 as uuid_v4 } from 'uuid';
import productService from '@/services/productService.js';

import ConfirmDlg from '../components/ConfirmDlg.vue';
import service from '../service/apiService';
import customizationService from '../services/customizationService';
import CategoryTree from '../components/customization/CategoryTree.vue';
import MultipleColorSelection from '@/components/editProducts/MulticolorSelection.vue';

import imageUploadService from '../../src/services/imageUploadService';

// @ is an alias to /src

const MAX_WIDTH = 75;
const MAX_HEIGHT = 75;

export default {
  name: 'Home',
  components: { CategoryTree, ConfirmDlg, MultipleColorSelection },
  data() {
    return {
      saveBtn: false,
      imgixConfig: '?auto=compress&w=400',
      allProduct: [],
      selectedProduct: {},
      customizationServiceObj: customizationService,
      componentFrontKey: 0,
      folderId: '',
      query: [],
      name: '',
      categories: [
        {
          name: 'Base',
          sequence: 1,
          show: false,
          isEdit: false,
          isLayerRemovable: true,
          price: 100,
          id: uuid_v4(),
          embroideryAssigned: false,
          layers: [
            {
              name: 'Color',
              sequence: 1,
              typeOfLayer: 'Color',
              show: false,
              isEdit: false,
              isLayerRemovable: true,
              showInNavigation: true,
              price: 100,
              toggles: [
                {
                  name: 'Lilac',
                  sequence: 1,
                  isEdit: false,
                  id: uuid_v4(),
                  baseTypes: [
                    {
                      name: 'Default',
                      id: uuid_v4(),
                      toggleImageBody: [
                        { name: 'Normal Front', image: 'IMG VALUE' },
                        { name: 'Normal Back', image: 'IMG VALUE' },
                        { name: 'Plus Front', image: 'IMG VALUE' },
                        { name: 'Plus Back', image: 'IMG VALUE' },
                      ],
                      embroidery: [],
                    },
                  ],
                  price: 100,
                  active: true,
                  hex: [],
                },
              ],
              active: true,
              color: 1,
              id: uuid_v4(),
            },
          ],
        },
      ],
      colorSelection: '',
      product: '',
      gender: '',
      embroiderySelection: '',
      model: ['Male', 'Female'],
      embroideries: [
        {
          name: 'Lighter',
          icon: '',
          sequence: 1,
        },
      ],
      selectedToggle: '',
      selectedLayer: '',
      selectedEmb: '',
      skuCode: '',
      productName: '',
      productCategories: [],
      designerName: '',
      bodytypes: ['Front', 'Back', 'Plus Front', 'Plus Back'],
      imageTypes: ['Core', 'Shading'],
      colors: ['Color 1', 'Color 2', 'Color 3', 'Color 4', 'Color 5'],
      currentEmbImageArray: [],
      productData: {},
      image: {
        size: '',
        height: '',
        width: '',
      },
      imageError: '',
      dialog: false,
      selectedStatus: '',
      realReferences: [],
      loader: false,
      currentEditingToggleName: '',
      colorPicker: '',
    };
  },
  methods: {
    updateEmbToggleName(layer, newToggleName, event) {
      if (layer.typeOfLayer == 'Embroidery') {
        console.log('newT', newToggleName);
        console.log('oldT', this.currentEditingToggleName);
        _.each(this.categories, (category) => {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              _.each(toggle.embroidery, (emb) => {
                if (emb.name == this.currentEditingToggleName) {
                  // emb.name = newToggleName;
                }
              });
            });
          });
        });
      }
    },
    async nextBtnClick() {
      this.saveBtn = false;
      if (this.$route.query.id) {
        await this.updateData();
      } else {
        await this.createCustomization();
      }
      // await this.syncColorToggleData();
    },
    async saveBtnClick() {
      this.saveBtn = true;
      if (this.$route.query.id) {
        await this.updateData();
      } else {
        await this.createCustomization();
      }
      await this.syncColorToggleData();
    },

    // async syncColorToggleData() {
    //   var colorToggles = [];
    //   _.each(this.categories, (category) => {
    //     if (category.name == 'Base') {
    //       _.each(category.layers, (layer) => {
    //         if (layer.typeOfLayer == 'Color') {
    //           colorToggles = layer.toggles;
    //         }
    //       });
    //     }
    //   });
    //   var temp = {};
    //   temp.productId = this.productData.product;
    //   temp.colorToggles = colorToggles;
    //   const result = await service.syncColorToggle(temp);
    //   if (result.status === 200) {
    //   }
    // },

    async createCustomization() {
      const data = {};
      data.gender = this.gender;
      data.name = this.name;
      data.sku = this.query;
      // this.createEmbObjectArray();
      await this.sequenceCategoriesArray();
      data.categories = this.categories;
      const result = await service.createCustomReplacement(data);
      console.log('result:::-', result);
      /* eslint no-underscore-dangle: 0 */
      if (result.status === 200) {
        if (result.data.data === 'Customization already assigned to this product') {
          this.$toasted.show('Customization already assigned to this product.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
        } else {
          this.categories = result.data.categories;
          this.gender = result.data.gender;
          this.name = result.data.name;
          this.query = result.data.sku;
          this.$router.push({
            name: 'CustomizeReplacement',
            query: { id: result.data._id },
          });

          if (this.saveBtn === false) {
            this.$router.push({
              name: 'CustomizeReplacementStep2',
              params: { id: result.data._id },
            });
          }
        }
      }
    },

    async updateData() {
      const data = {};
      // this.createEmbObjectArray();
      data.gender = this.gender;
      data.name = this.name;
      data.sku = this.query;
      console.log('eeeee', data);
      await this.sequenceCategoriesArray();
      data.categories = this.categories;
      const result = await service.updateReplacement(this.$route.query.id, data);
      if (result.status === 200) {
        this.categories = result.data.categories;
        this.gender = result.data.gender;
        this.name = result.data.name;
        this.query = result.data.sku;
        // this.productData = result.data;
        if (this.saveBtn === false) {
          this.$router.push({
            name: 'CustomizeReplacementStep2',
            params: { id: this.$route.query.id },
          });
        }
      }
    },

    async getProductDetailsUsingId(id) {
      var skuProductDetails = await customizationService.getOneAdmin(id);
      if (skuProductDetails.status === 200) {
        this.productName = skuProductDetails.data.parentProductData.name;
        this.skuCode = skuProductDetails.data.parentProductData.sku;
        this.designerName = skuProductDetails.data.userData.brandName
          ? skuProductDetails.data.userData.brandName
          : '';
        if (skuProductDetails.data.parentProductData.compoundProducts.length > 0) {
          var catArray = [];
          _.map(skuProductDetails.data.childProductData, (childData) => {
            catArray.push(childData.productCategory);
          });
          this.productCategories = catArray;
        } else {
          this.productCategories = [skuProductDetails.data.parentProductData.productCategory];
        }
      }
    },
    // createEmbroidery() {
    //   this.embroideries.push({
    //     id: uuid_v4(),
    //     name: '',
    //     icon: '',
    //     sequence: this.embroideries.length + 1,
    //   });
    // },

    deleteEmbroidery(embroidery) {
      _.remove(this.embroideries, (n) => {
        const obj = n === embroidery;
        return obj;
      });
      this.$forceUpdate();
    },
    deleteTheReferenceOfEmb(deletedToggle) {
      var catIds = [];
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            _.each(layer.embroideryMapping, (embMapObj) => {
              // if (embMapObj.flag) {
              catIds.push(embMapObj.categoryId);
              //}
            });
          }
        });
      });
      _.each(this.categories, (category) => {
        const obj = _.find(catIds, (n) => {
          const obj = n === category.id;
          return obj;
        });
        if (obj) {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              _.each(toggle.baseTypes, (baseType) => {
                var filteredArray = [];
                _.each(baseType.embroidery, (emb) => {
                  if (deletedToggle.id != emb.id) {
                    filteredArray.push(emb);
                  }
                });
                baseType.embroidery = filteredArray;
              });
            });
          });
        }
      });
      this.$forceUpdate();
    },

    deleteTheReferenceOfEmbForLayer(deletedLayer) {
      var catIds = [];
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.id == deletedLayer.id) {
            _.each(layer.embroideryMapping, (embMapObj) => {
              catIds.push(embMapObj.categoryId);
            });
          }
        });
      });
      _.each(this.categories, (category) => {
        const obj = _.find(catIds, (n) => {
          const obj = n === category.id;
          return obj;
        });
        if (obj) {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              _.each(toggle.baseTypes, (baseType) => {
                baseType.embroidery = [];
              });
            });
          });
        }
      });
      this.$forceUpdate();
    },

    deleteTheReferenceOfEmbForCategory(deletedCategory) {
      var catIds = [];
      _.each(this.categories, (category) => {
        if (category.id == deletedCategory.id) {
          _.each(category.layers, (layer) => {
            if (layer.typeOfLayer == 'Embroidery') {
              _.each(layer.embroideryMapping, (embMapObj) => {
                catIds.push(embMapObj.categoryId);
              });
            }
          });
        }
      });
      _.each(this.categories, (category) => {
        const obj = _.find(catIds, (n) => {
          const obj = n === category.id;
          return obj;
        });
        if (obj) {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              _.each(toggle.baseTypes, (baseType) => {
                baseType.embroidery = [];
              });
            });
          });
        }
      });
      this.$forceUpdate();
    },

    createCategory() {
      this.categories.push({
        id: uuid_v4(),
        name: '',
        show: false,
        isEdit: false,
        isLayerRemovable: true,
        price: 0,
        sequence: this.categories.length + 1,
        layers: [],
        embroideryAssigned: false,
      });
    },
    deleteCategory(category) {
      this.deleteTheReferenceOfEmbForCategory(category);
      _.remove(this.categories, (n) => {
        const obj = n === category;
        return obj;
      });
      this.$forceUpdate();
    },
    toggleCategoryShow(category) {
      const finalCategory = _.find(this.categories, (n) => {
        const obj = n === category;
        return obj;
      });
      finalCategory.show = !finalCategory.show;
      this.$forceUpdate();
    },
    createLayer(category) {
      category.layers.push({
        name: '',
        icon: '',
        sequence: category.layers.length + 1,
        toggles: [],
        typeOfLayer: 'Image',
        show: false,
        isEdit: false,
        isLayerRemovable: true,
        showInNavigation: true,
        price: 0,
        id: uuid_v4(),
        colorMapping: [],
      });
    },
    deleteLayer(category, layer) {
      if (layer.typeOfLayer == 'Embroidery') {
        this.deleteTheReferenceOfEmbForLayer(layer);
      }
      _.remove(category.layers, (n) => {
        const obj = n === layer;
        return obj;
      });
      category.selectedLayer = 0;
      this.$forceUpdate();
    },
    toggleLayerShow(category, layer) {
      const finalLayer = _.find(category.layers, (n) => {
        const obj = n === layer;
        return obj;
      });
      finalLayer.show = !finalLayer.show;
      this.$forceUpdate();
    },
    createToggle(layer) {
      layer.toggles.push({
        name: '',
        id: uuid_v4(),
        icon: '',
        hex: [],
        sequence: layer.toggles.length + 1,
        isEdit: false,
        baseTypes: [
          {
            name: 'Default',
            id: uuid_v4(),
            toggleImageBody: [
              { name: 'Normal Front', image: 'IMG VALUE' },
              { name: 'Normal Back', image: 'IMG VALUE' },
              { name: 'Plus Front', image: 'IMG VALUE' },
              { name: 'Plus Back', image: 'IMG VALUE' },
            ],
            embroidery: [],
          },
        ],
        price: 0,
        active: true,
      });
    },
    deleteToggle(layer, toggle) {
      if (layer.typeOfLayer == 'Embroidery') {
        this.deleteTheReferenceOfEmb(toggle);
      }
      _.remove(layer.toggles, (n) => {
        const obj = n === toggle;
        return obj;
      });
      layer.selectedToggle = 0;
      this.$forceUpdate();
    },
    async getProducts() {
      const result = await service.getAllProductsForCustomization();
      this.allProduct = result.data;
    },

    async getData() {
      const result = await service.getOneReplacement(this.$route.query.id);
      await this.sequenceCategoriesArray();
      this.categories = result.data.categories;
      this.gender = result.data.gender;
      // this.embroideries = result.data.embroideries;
      this.selectedStatus = result.data.customizationStatus;
      this.name = result.data.name;
      this.query = result.data.sku;
    },
    async layerImagePickerClick(event, layer) {
      var check = await this.checkSelectedImageSize(event.target.files[0]);
      // if (check.height > MAX_HEIGHT && check.width > MAX_WIDTH) {
      // //  this.dialog = true;
      //   return;
      // }
      this.componentFrontKey += 1;
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
          const url = result.data.meta.location + this.imgixConfig;
          /* eslint-disable */
          layer.icon = url;
        }
      });
    },

    async toggleImagePickerClick(event, toggle) {
      var check = await this.checkSelectedImageSize(event.target.files[0]);
      // if (check.height > MAX_HEIGHT && check.width > MAX_WIDTH) {
      //   this.dialog = true;
      //   return;
      // }
      this.componentFrontKey += 1;
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
          const url = result.data.meta.location + this.imgixConfig;
          /* eslint-disable */
          toggle.icon = url;
        }
      });
    },

    embImagePickerClick(event, emb) {
      this.componentFrontKey += 1;
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
          const url = result.data.meta.location + this.imgixConfig;
          emb.icon = url;
        }
      });
    },
    // createEmbObjectArray() {
    //   var embArray = [];
    //   _.map(this.embroideries, (emb) => {
    //     var temp = {};
    //     temp.name = emb.name;
    //     temp.images = [
    //       { name: 'Normal Front', image: 'IMG VALUE' },
    //       { name: 'Normal Back', image: 'IMG VALUE' },
    //       { name: 'Plus Front', image: 'IMG VALUE' },
    //       { name: 'Plus Back', image: 'IMG VALUE' },
    //     ];
    //     embArray.push(temp);
    //   });
    //   _.each(this.categories, (category) => {
    //     _.each(category.layers, (layer) => {
    //       _.each(layer.toggles, (toggle) => {
    //         if (_.isEmpty(toggle.embroidery)) {
    //           toggle.embroidery = embArray;
    //         }
    //       });
    //     });
    //   });
    // },

    async sequenceCategoriesArray() {
      _.each(this.categories, (category) => {
        category.layers = _.orderBy(category.layers, [, 'sequence'], ['desc', 'asc']);
        _.each(category.layers, (layer) => {
          layer.toggles = _.orderBy(layer.toggles, [, 'sequence'], ['desc', 'asc']);
        });
      });
      this.categories = _.orderBy(this.categories, [, 'sequence'], ['desc', 'asc']);
    },
    async checkSelectedImageSize(file) {
      const photoUrl = URL.createObjectURL(file);
      const image = new Image();
      const imageDimensions = await new Promise((resolve) => {
        image.onload = () => {
          const dimensions = {
            height: image.height,
            width: image.width,
          };
          resolve(dimensions);
        };
        image.src = photoUrl;
      });
      return imageDimensions;
    },
    async statusUpdate(value, event) {
      let status = value;
    },
    imagePickerClick(event) {
      this.loader = true;
      this.componentFrontKey += 1;
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 1000,
          });
          const url = result.data.meta.location + this.imgixConfig;
          this.realReferences.push(url);
          console.log(this.realReferences, 'realReferences__');
          this.loader = false;
          /* eslint-disable */
        }
      });
    },
    async uploadFolderImages() {
      this.loader = true;
      const data = {};
      data.folderId = this.folderId;
      const result = await productService.uploadImagesFromBox(data);
      console.log(result, 'result-avi');
      result.data.map((item) => {
        this.realReferences.push(item.downloadUrl);
      });
      console.log(result, 'box upload');
      //await this.boxUpload();
      this.loader = false;
    },
    fetchProductImages() {
      console.log(this.selectedProduct, 'selectedProduct');
      if (Object.keys(this.selectedProduct).length === 0) {
        this.$store.commit('setSnackbar', {
          content: 'No Product Images Found !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.selectedProduct &&
          this.selectedProduct.images &&
          this.selectedProduct.images.map((item) => {
            if (this.realReferences.includes(item.imageUrl)) {
            } else {
              this.realReferences.push(item.imageUrl);
            }
          });
        console.log(this.realReferences, 'real-References');
      }
    },
    async deleteCustomizationPrimaryColor(hex) {
      var temp = {};
      temp.customizationId = this.$route.query.id;
      temp.color = hex;
      var result = await customizationService.deleteCustomizationColorFromProductPrimaryColor(temp);
      this.$forceUpdate();
    },

    async ConfirmDeleteClr(clr, catId, layId, toggId) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          `Are you sure you want to delete this "${clr}" from Primary Colors?`
        )
      ) {
        this.categories.map((cate) => {
          cate.layers.map((lay) => {
            lay.toggles.map((togg) => {
              if (cate.id == catId && lay.id == layId && togg.id == toggId) {
                togg.hex.splice(0, 1);
              }
            });
          });
        });
        this.$forceUpdate();
        this.deleteCustomizationPrimaryColor(clr);
        this.updateData();
      }
    },
    async addSku() {
      const data = {};
      data.sku = '';
      console.log('data::::::', data);
      this.query.push(data);
    },
    deleteSKU(index) {
      this.query.splice(index, 1);
    },
  },

  created() {
    // this.getProducts();
    if (this.$route.query && this.$route.query.id) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.next-button-section {
  margin: 30px 0;
}
.sub-text {
  font-size: 12px;
  color: #999;
}
// .category-tree {
//   position: fixed;
//   zoom: 0.7;
//   top: 10px;
// }
.embroidery-section {
  padding: 20px;
  background: #e0e1ff;
  border-radius: 20px;
}
.real-references {
  height: 100%;
  width: 650px;
}
.image-container {
  width: 600px;
  height: 100%;
}

.category-section {
  padding: 20px;
  background: #e0eeff;
  border-radius: 20px;
}
.rounded-color {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.layer-section {
  background: #e0fcfe;
  border-radius: 20px;
  padding: 20px;
}
.toggle-section {
  background: #fee0e0;
  border-radius: 20px;
  padding: 20px;
  // width: 900px;
}

.delete-button-holder {
  position: relative;
}
.delete-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 19px;
}
.show-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 60px;
}
.image-preview {
  height: 200px;
  width: 80px;
}
.margin-left-1 {
  margin: 10px 0px 10px 30px;
}
.pull-right {
  float: right;
}
.padding-10 {
  padding: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.price-range {
  width: 100px;
}
.category {
  margin-top: 80px;
  .model-name {
    font-size: 15px;
  }
  .category-name {
    font-size: 32px;
  }
  .layer {
    margin-top: 20px;
    select {
      font-size: 15px;
    }
    font-size: 26px;
    margin-left: 20px;
    .toggle {
      .toggle-name {
        margin-top: 10px;
      }
      font-size: 20px;
      margin-left: 20px;
      .images-upload {
        font-size: 16px;
        margin-left: 20px;
        .image-upload-section {
          font-size: 14px;
          margin-left: 20px;
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
          display: inline-block;
          text-align: center;
          line-height: 100px;
        }
      }
    }
  }
}
.skucodes {
  place-items: center;
}
.product-name {
  width: 50% !important;
}
</style>
